<template>
	<div class="page">
		<div class="banner" style="background-color: #f8f8f8;">
			<div id="demo" class="carousel slide" data-ride="carousel">
				<!-- 指示符 -->
				<!-- <ul class="carousel-indicators">
					<li data-target="#demo" :class="[ index == 0 ? 'active' : '']" :data-slide-to="index" v-for="(item,index) in bannerList" :key="index"></li>
				</ul> -->
				<!-- 轮播图片 -->
				<div class="carousel-inner">
					<div class="carousel-item" :class="[index == 0 ? 'active' : '']" v-for="(item,index) in bannerList" :key="index">
						<img style="width: 100%;height:auto;" class="img" :src="item.imageUrl" />
					</div>
				</div>
				<!-- 左右切换按钮 -->
				<a class="carousel-control-prev" href="#demo" data-slide="prev"><img class="img" src="../assets/static/home_prev.png"/></a>
				<a class="carousel-control-next" href="#demo" data-slide="next"><img class="img" src="../assets/static/home_next.png"/></a>
			</div>
		</div>
		<!-- 知识产权检索 -->
		<div class="block58"></div>
		<div class="huaxue_zscqjs_box">
			<!-- <div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">知识产权检索</div>
				<div class="type_desc fontsize14">INTELLECTUAL PROPERTY SEARCH</div>
			</div>
			<div class="block30"></div>
			<div class="search_box w1239" @click="handleSearche('')">
				<div class="inputdiv">
					<input class="fontsize16" disabled placeholder="输入专利名称/专利号/申请人"/>
				</div>
				<div class="searchbtn fontsize18">检索</div>
			</div>
			<div class="block30" v-if="typeList1.length>0"></div> -->
			<!-- <div class="huaxue_findbox w1260" v-if="typeList1.length>0">
				<div class="numitem" v-for="(item,index) in typeList1" :key="index">
					<img class="img" :src="item.iconUrl || require('../assets/static/logo.png')"/>
					<div class="text1 fontsize20">{{item.title}}</div>
					<div class="text2 fontsize18"><span class="span1">{{item.contents}}</span>{{item.unit}}</div>
				</div>
			</div>
			<div class="block30"></div> -->
			<div class="huaxue_findbox w1260">
				<div class="finditem" v-for="(item,index) in typeList2" :key="index">
					<img class="img" :src="item.imageUrl" @click="$util.windowOpen(item.redirectUrl)"/>
				</div>
			</div>
		</div>
		<!-- 知识产权运营 -->
		<div class="block58"></div>
		<div class="huaxue_zscqyy_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">知识产权运营</div>
				<div class="type_desc fontsize14">INTELLECTUAL PROPERTY OPERATION</div>
			</div>
			<div class="block10"></div>
			<div class="huaxue_yybox w1260">
				<div class="yyitem" v-for="(item,index) in typeList3" :key="index">
					<img class="img" :src="item.imageUrl || require('../assets/static/logo.png')"/>
					<div class="block10"></div>
					<div class="text2 fontsize18">{{item.title}}</div>
				</div>
			</div>
		</div>
		<!-- 知识产权展示 -->
		<div class="block58"></div>
		<div class="huaxue_zscqzs_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">知识产权交易</div>
				<div class="type_desc fontsize14">INTELLECTUAL PROPERTY</div>
			</div>
			<div class="huaxue_home_type w1239">
				<div class="title fontsize38"></div>
				<div class="typelist">
					<div class="typeobj fontsize22" @click="$util.routerPath('/deal')">查看更多</div>
				</div>
			</div>
			<div class="huaxue_zscqzs relative w1240" v-if="swiperOptions.isshow">
				<div class="btn_prev" @click="slidePrev"><img src="../assets/static/icon_left.png"></div>
				<div class="btn_next" @click="slideNext"><img src="../assets/static/icon_right.png"></div>
				<div>
					<swiper ref="mySwiper" :options="swiperOptions">
					    <swiper-slide v-for="(item,index) in tuijianList" :key="index">
					    	<div class="tuijian_item">
					    		<div class="img"><img :src="item.imgUrl || require('../assets/static/noimg.png')"></div>
					    		<div class="block10"></div>
								<div class="title fontsize14 clamp">{{item.title}}</div>
					    		<div class="block10"></div>
					    		<div class="desc fontsize12">申请号：{{item.applyNo}}</div>
					    		<div class="desc fontsize12">申请人：{{item.applicant}}</div>
					    		<div class="desc fontsize12">申请日：{{item.applyDate}}</div>
					    		<div class="more fontsize16" @click="$util.routerPath('/dealDetail',{uid:item.id})">查看更多</div>
					    	</div>
					    </swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		<!-- 最新资讯 -->
		<div class="block58"></div>
		<div class="huaxue_new_type_box w1239">
			<div class="type_name fontsize38">知识产权资讯</div>
			<div class="type_desc fontsize14">INTELLECTUAL PROPERTY INFORMATION</div>
		</div>
		<div class="huaxue_home_type w1239">
			<div class="title fontsize38"></div>
			<div class="typelist">
				<div class="typeobj fontsize22" @click="$util.routerPath('/infoItem',{type:'chanquanzixun'})">查看更多</div>
			</div>
		</div>
		<div class="huaxue_notice w1239" v-if="noticeList.length>0">
			<div class="left">
				<div class="img"><img src="../assets/static/new_icon.png"></div>
				<div class="title clamp2 fontsize18" @click="clickarticleDetail(noticeList[0].id)">{{noticeList[0].title}}</div>
				<div class="desc clamp4 fontsize16 ql-indent-1" @click="clickarticleDetail(noticeList[0].id)" v-html="noticeList[0].body"></div>
			    <div class="timer fontsize16" @click="clickarticleDetail(noticeList[0].id)">{{noticeList[0].releaseTime || noticeList[0].timer}}</div>
			</div>
			<div class="right" v-if="noticeList.length>1">
				<div class="notice_item" v-for="(item,index) in noticeList" :key="index" v-if="index!=0" @click="clickarticleDetail(item.id)">
					<div class="title fl clamp fontsize18">{{item.title}}</div>
					<div class="timer fr fontsize18">{{item.releaseTime || item.timer}}</div>
				</div>
			</div>
		</div>
		<div class="huaxue_notice w1239" v-if="noticeList.length<=0">
			<div class="left">
				<div class="img"><img src="../assets/static/new_icon.png"></div>
				<div class="title clamp2 fontsize14"></div>
				<div class="desc clamp4 fontsize14">暂无相关信息！</div>
			</div>
		</div>
		<!-- 深度合作，即刻联系 -->
		<div class="block58"></div>
		<!-- <div class="home_call_box">
			<div class="home_con">
				<div class="call_con w1239">
					<div class="text fontsize36">深度合作，即刻联系</div>
					<div class="more fontsize16" @click="$util.routerPath('/mine',{type:2})">查看更多</div>
				</div>
			</div>
			<div class="img"><img src="../assets/static/home_call_icon.png"></div>
		</div> -->
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
//页面引入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			bannerList: [],
			typeList1:[],
			typeList2:[],
			typeList3:[],
			swiperOptions:{
				isshow:false,
				slidesPerView: 5,
			},
			noticeList: [], //资讯列表
			tuijianList: [], //推荐服务、专利，知识产权展示
			
		};
	},
	mounted: function() {
		if(document.body.clientWidth<750){
			//手机端
			this.swiperOptions.slidesPerView = 1
		}
		// let width = $("body").width();
		// console.log(width)
		//初始化
		this.initPage()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		...mapMutations(["SET_pvNum"]),
		//初始化
		async initPage(){
			//轮播图和广告
			await this.getwheelPage(0)
			await this.getwheelPage(2)
			await this.getwheelPage(3)
			this.getIndexData()
			this.getJiaoyixinxi()
			this.getZhuanlijiaoyi()
		},
		//跳转搜索
		handleSearche(url){
			if(!this.isLogin){
				this.$router.push({
					path: "/login"
				});
				return;
			}
			if(url){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				console.log("无跳转路径")
				this.$message.info("此模块暂未开放")
			}
		},
		//上一页
		slidePrev() {
		    this.$refs.mySwiper.swiper.slidePrev();
		},
		//下一页
		slideNext() {
		    this.$refs.mySwiper.swiper.slideNext()
		},
		//获取轮播图
		getwheelPage(type) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 18
				params["type"] = type
				this.$http.post('frontEnd/index/getWheel', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						// imageUrl:require("../assets/static/banner1.png")
						if(records.length>0){
							if(type==0){
								_this.bannerList = records
							}else if(type==2){
								_this.typeList2 = records
							}else if(type==3){
								_this.typeList3 = records
							}else{}
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//获取统计数据
		getIndexData() {
			var _this = this;
			var params = {};
			this.$http.post('frontEnd/index/getIndexData', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					var typeList1 = []
					if(dataobj.firstModule.title){
						typeList1.push(dataobj.firstModule)
					}
					if(dataobj.secondModule.title){
						typeList1.push(dataobj.secondModule)
					}
					if(dataobj.thirdModule.title){
						typeList1.push(dataobj.thirdModule)
					}
					if(dataobj.fourthModule.title){
						typeList1.push(dataobj.fourthModule)
					}
					if(dataobj.fifthModule.title){
						typeList1.push(dataobj.fifthModule)
					}
					if(dataobj.sixthModule.title){
						typeList1.push(dataobj.sixthModule)
					}
					_this.typeList1 = typeList1
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取资讯
		getJiaoyixinxi() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 8,
			};
			this.$http.post('frontEnd/index/getChanquanzixun', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						if(records[0].contents){
							records[0]["body"] = _this.$util.filtersText(records[0].contents,218)
						}
						records.forEach((item,index)=>{
							if(item.createDate){
								item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
							}
							if(item.releaseTime){
								item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
							}
						})
						_this.noticeList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//推荐服务  知识产权展示
		getZhuanlijiaoyi() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize:15,
			};
			this.$http.post('frontEnd/index/getZhuanlijiaoyi', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.tuijianList = records
						_this.swiperOptions.isshow = true
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		
	}
};
</script>
<style lang="scss" scoped></style>
